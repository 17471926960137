const STORAGE = {
  launch: 'launch',
  cookieNotice: 'cookies',
};

const dissmissCookies = () => {
  localStorage.setItem(STORAGE.cookieNotice, 1);
};

const getCookiesStatus = () => {
  return !!parseInt(localStorage.getItem(STORAGE.cookieNotice), 10);
};

const dissmissLaunchSubscribe = () => {
  localStorage.setItem(STORAGE.launch, 1);
};

const getLaunchSubscribeStatus = () => {
  return !!parseInt(localStorage.getItem(STORAGE.launch), 10);
};

export {
  dissmissLaunchSubscribe,
  getLaunchSubscribeStatus,
  dissmissCookies,
  getCookiesStatus,
};
