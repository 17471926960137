import { useEffect, useState } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import SiteHeader from 'components/site-header';
import SiteFooter from 'components/site-footer';

import { useUi } from 'app/uiStore';

import ROUTE from 'config/routes';
import CookieBanner from 'components/cookie-banner';
import { getCookiesStatus } from 'helpers/local-storage';

const Layout = ({ children }) => {
  const router = useRouter();
  const { closeBanner, isBannerVisible } = useUi();

  const [cookieNotice, setCookieNotice] = useState(true);

  useEffect(() => {
    setCookieNotice(getCookiesStatus());
  }, []);

  useEffect(() => {
    if (isBannerVisible) closeBanner();
  }, [isBannerVisible]);

  return (
    <div className={`app${isBannerVisible ? ' banner-visible' : ''}`}>
      <Head>
        <meta name="theme-color" content="#3521b5" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, maximum-scale=1.0, user-scalable=no"
        />

        <link rel="icon" href="/favicons/favicon.ico" />

        <link
          rel="icon"
          type="image/png"
          href="/favicons/16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicons/32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicons/192.png"
          sizes="192x192"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicons/512.png"
          sizes="512x512"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicons/apple-touch-icon.png"
          sizes="180x180"
        />
      </Head>
      <SiteHeader />

      <div className="app__page">{children}</div>

      {!cookieNotice && <CookieBanner onAccept={() => setCookieNotice(true)} />}

      {/* FIXME: setup and get Next.js buildId here */}

      {router.pathname !== ROUTE.signin && <SiteFooter />}
    </div>
  );
};

export default Layout;
