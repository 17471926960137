import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSession, signOut } from 'next-auth/react';
import moment from 'moment';

import { reloadSession } from 'lib/reloadNextAuthSession';

const AuthWrapper = ({ children }) => {
  const { data: session, status } = useSession();
  const router = useRouter();

  const [hasAuth, setHasAuth] = useState(false);

  const refreshTokenExpiry = session?.refresh?.expires;
  const accessTokenExpiry = session?.access?.expires;

  useEffect(() => {
    if (session) {
      setHasAuth(true);

      if (moment.utc().isAfter(refreshTokenExpiry)) {
        signOut();
      }

      if (moment.utc().isAfter(accessTokenExpiry)) {
        reloadSession();
      }
    }

    if (hasAuth && !session) {
      signOut();
    }
  }, [session, router, hasAuth]);

  return <>{children}</>;
};

export default AuthWrapper;
