
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { useEffect, useState } from 'react';
import { SessionProvider } from 'next-auth/react';

import Layout from 'components/layout';
import AuthWrapper from 'components/auth-wrapper/AuthWrapper';

import 'styles/globals.scss';
import 'styles/policy-pages.scss';

// TODO: manage this better
import 'pages/apply/Apply.scss';
import 'pages/courses/Courses.scss';
import 'pages/my-courses/MyCourses.scss';
import 'pages/calendar/Calendar.scss';
import 'pages/learning-paths/LearningPaths.scss';
import 'pages/courses/[courseId]/CourseDetails.scss';
import 'pages/learning-paths/[learningPathId]/LearningPathDetails.scss';
import 'pages/home/Home.scss';
import 'pages/auth/signin/SignIn.scss';

import 'components/block-intro/BlockIntro.scss';
import 'components/course-type-label/CourseTypeLabel.scss';
import 'components/cookie-banner/CookieBanner.scss';
import 'components/grid-item/GridItem.scss';
import 'components/loading/Loading.scss';
import 'components/site-footer/SiteFooter.scss';
import 'components/site-header/SiteHeader.scss';

const MyApp = ({ Component, pageProps }) => {
  // Fixing React18 and NextJS hydration error
  // Link: https://stackoverflow.com/questions/71706064/react-18-hydration-failed-because-the-initial-ui-does-not-match-what-was-render
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  if (typeof window === 'undefined') {
    return <></>;
  }

  return (
    <SessionProvider session={pageProps.session}>
      <AuthWrapper>
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </AuthWrapper>
    </SessionProvider>
  );
};

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  