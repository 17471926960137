const ENV = {
  dev: 'development',
  stage: 'staging',
  prod: 'production',
};

const getEnv = (baseUrl) => {
  switch (true) {
    case baseUrl.includes('tdlbox.com'):
      return ENV.stage;
    case baseUrl.includes('tdlschool.com'):
      return ENV.prod;
    default:
      return ENV.dev;
  }
};

const isInternal = () => process.env.NEXT_PUBLIC_INTERNAL === 'true';

export { ENV, getEnv, isInternal };
