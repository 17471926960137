import moment from 'moment';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^([+]?[(]?[0-9]{3})?[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;
const noSpecialChars = /[0-9~\-\_`!@#$%\^&*()+=\\[\]\\';,/{}|\\":\\<>\?]/g;

const validationRules = {
  REQUIRED: { required: 'This field is required' },
  EMAIL: {
    pattern: {
      value: emailRegex,
      message: 'Please provide a valid email address',
    },
  },
  LETTERS: {
    validate: (value) =>
      !noSpecialChars.test(value) || 'Invalid value, only letters allowed',
  },
  PHONE: {
    pattern: {
      value: phoneRegex,
      message: 'Please provide a valid phone number',
    },
  },
};

const cleanInput = (e) => (e.target.value = e.target.value.trim());

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));

  return `rgba(${r},${g},${b},${alpha})`;
};

const calculateCourseLength = (topics) =>
  topics.reduce((acc, { duration }) => acc + duration, 0);

const calculatePathLength = (courses) => {
  let lpLength = 0;

  courses.forEach((c) => {
    lpLength += calculateCourseLength(c.topics);
  });

  return lpLength;
};

const getUploadURL = (path) => `${process.env.NEXT_PUBLIC_API_URL}${path}`;

const getDaysUntil = (endDate, date = new Date()) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(date);
  const secondDate = new Date(endDate);

  const daysLeft = Math.round(Math.abs((secondDate - firstDate) / oneDay));

  if (daysLeft > 0) return { timeLeft: daysLeft, key: 'MISC.DAY' };

  const delta = Math.abs(secondDate - firstDate) / 1000;
  const hoursLeft = Math.floor(delta / 3600) % 24;

  return { timeLeft: hoursLeft - 3, key: 'MISC.HOUR' };
};

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const now = moment(startDate).clone();
  const dates = [];

  const end =
    moment(endDate).format('D') < moment(startDate).format('D')
      ? moment(startDate).endOf('month')
      : endDate;

  while (now.isSameOrBefore(end)) {
    dates.push(Number(now.format('D')));
    now.add(1, 'days');
  }

  return dates;
};

const groupTechnologies = (courseList) => {
  const tech = [];

  courseList.forEach(({ technologies }) => {
    technologies.forEach((t) => {
      const exists = tech.some(
        (techItem) => techItem.toLowerCase() === t.toLowerCase(),
      );

      if (!exists) tech.push(t);
    });
  });

  const technologies = tech
    .sort((t1, t2) => t1.localeCompare(t2))
    .map((t, index) => ({
      id: `tech-${index}`,
      value: t.toLowerCase().split(' ').join('-'),
      text: t,
    }));

  return technologies;
};

export {
  validationRules,
  hex2rgba,
  calculateCourseLength,
  calculatePathLength,
  enumerateDaysBetweenDates,
  getUploadURL,
  getDaysUntil,
  groupTechnologies,
  cleanInput,
};
