import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import useTranslation from 'next-translate/useTranslation';

import api from 'api/requests';

import { EMAIL, OFFICE } from 'config/constants';
import ROUTE from 'config/routes';

import { cleanInput, validationRules } from 'helpers/utils';

import Facebook from 'public/static/assets/icons/facebook.svg';
import Instagram from 'public/static/assets/icons/instagram.svg';
import Linkedin from 'public/static/assets/icons/linkedin-rounded.svg';

const SiteFooter = () => {
  const { data: session } = useSession();

  const { t } = useTranslation('common');

  // TODO: create subscribe component
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const router = useRouter();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  if (router.pathname === ROUTE.login) {
    return null;
  }

  const onSubmit = async ({ email }) => {
    if (email.trim() === '') return;

    setSuccess(false);
    setError('');

    const data = {
      email,
    };

    const subscriber = await api.POST('/subscribers', data);

    if (subscriber?.email) {
      setSuccess(true);
      reset();
    } else {
      setError('Something went wrong!');
    }
  };

  return (
    <footer className={`site-footer${session ? ' site-footer--internal' : ''}`}>
      <div className="u-container">
        <Link href={ROUTE.home}>
          <a className="site-footer__logo-link">
            <img
              src="/static/assets/logo-white.svg"
              width="244"
              height="25"
              alt="TDL School logo"
            />
          </a>
        </Link>

        {!session && (
          <div className="top">
            <div className="site-footer__contacts col">
              <div className="header">{t('FOOTER.CONTACT_US')}</div>

              <a className="email" href={`mailto:${EMAIL}`}>
                {EMAIL}
              </a>

              <p className="phone">
                <span>+371</span>26948033
              </p>

              <p>{t('FOOTER.TEXT.CONTACTS')}</p>
            </div>

            <div className="site-footer__locations col">
              <ul>
                <li>
                  <div className="header">{t('FOOTER.LOCATION')}</div>
                </li>
                <li>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${OFFICE.riga.searchQuery}`}
                    target="_blank"
                  >
                    {t('FOOTER.ADDRESS.RIGA')}
                  </a>
                </li>
              </ul>
            </div>

            <div className="site-footer__subscribe col">
              <p>{t('FOOTER.NEWSLETTER')}</p>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input">
                  <input
                    className="input__field"
                    name="email"
                    placeholder={t('MISC.YOUR_EMAIL')}
                    autoComplete="off"
                    onBlur={cleanInput}
                    {...register('email', {
                      ...validationRules.REQUIRED,
                      ...validationRules.EMAIL,
                    })}
                  />

                  {(errors?.email || error) && (
                    <span className="input__error">
                      {errors?.email?.message || error}
                    </span>
                  )}

                  {!errors?.email?.message && success && (
                    <span className="input__success">
                      {t('MISC.SUBSCRIBE_SUCCESS')}
                    </span>
                  )}
                </div>

                <button type="submit">{t('MISC.SUBSCRIBE')}</button>
              </form>
            </div>
          </div>
        )}

        <div className="bottom">
          <ul className="site-footer__legal">
            <li>
              <Link href={ROUTE.privacyPolicy}>
                <a>{t('PAGES.PRIVACY_POLICY')}</a>
              </Link>
            </li>

            <li>
              <Link href={ROUTE.cookiePolicy}>
                <a>{t('PAGES.COOKIE_POLICY')}</a>
              </Link>
            </li>

            <li>
              <Link href={ROUTE.refundPolicy}>
                <a>{t('PAGES.REFUND_POLICY')}</a>
              </Link>
            </li>

            <li>
              <Link href={ROUTE.termsOfUse}>
                <a>{'Terms of use'}</a>
              </Link>
            </li>
          </ul>

          <div className="site-footer__copyright">{t('FOOTER.ALL_RIGHTS')}</div>

          <ul className="site-footer__social">
            <li>
              <a
                href="https://www.linkedin.com/company/tdlschoolcom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/tdlschoolcom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/tdlschoolcom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
