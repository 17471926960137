import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useUi = create(
  persist(
    (set) => ({
      isBannerVisible: false,
      closeBanner: () => {
        set((state) => {
          state.isBannerVisible = false;
        });
      },
    }),
    {
      name: 'uistore',
    },
  ),
);
