import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

import { useUi } from 'app/uiStore';

import ROUTE from 'config/routes';

import CloseIcon from 'public/static/assets/icons/close-gray.svg';

import style from './SummerSchoolBanner.module.scss';

// TODO: add visiblity prop and handle disappearance more smoother
const SummerSchoolBanner = () => {
  const { push } = useRouter();
  const { t } = useTranslation('common');
  const { closeBanner } = useUi();

  const handleLearnMore = () => {
    push(`${ROUTE.calendar}?date=2022-07`);

    setTimeout(() => {
      closeBanner();
    }, 1200);
  };

  return (
    <div className={style.main}>
      <div className={style.container}>
        <div className={style.banner}>
          <button className={style.close} onClick={closeBanner}>
            <CloseIcon />
          </button>

          <div className={style['left-side']}>
            <h1 className={style.title}>
              <Trans
                i18nKey="common:SCHOOL_BANNER.TITLE"
                components={{
                  a: <span />,
                }}
              />
            </h1>
          </div>

          <div className={style['right-side']}>
            <p className={style.description}>
              {t('SCHOOL_BANNER.DESCRIPTION')}
            </p>

            <div className={style.cta}>
              <button onClick={handleLearnMore} className={style.btn}>
                {t('SCHOOL_BANNER.BTN_TEXT')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummerSchoolBanner;
