const KEY = {
  recaptcha_site_key: '6Lc_p8wUAAAAAM6BNWNUmC0Eq7UHdaP2p2P7ApTE',
};

const EMAIL = 'applicants@tdlschool.com';

const OFFICE = {
  riga: {
    address: 'FOOTER.ADDRESS.RIGA',
    searchQuery: 'Vingrotāju iela 1, Rīga, LV-1010',
  },
};

const LEARNING_PATHS = false;

const TDL_SCHOOL_NO = 4;

export { EMAIL, OFFICE, KEY, LEARNING_PATHS, TDL_SCHOOL_NO };
