import axios from 'config/axios';

const GET = async (endpoint, accessToken = null) => {
  let config = {};

  if (accessToken !== null) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }

  try {
    const response = await axios.get(endpoint, config);

    return response.data;
  } catch (error) {
    // TODO: handle error gracefully
    return {};
  }
};

const POST = async (endpoint, data, accessToken = null) => {
  let config = {};

  if (accessToken !== null) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }

  try {
    const response = await axios.post(endpoint, data, config);

    return response.data;
  } catch (error) {
    // TODO: handle error gracefully
    return {};
  }
};
const PATCH = async (endpoint, data, accessToken = null) => {
  let config = {};

  if (accessToken !== null) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }

  try {
    const response = await axios.patch(endpoint, data, config);

    return response.data;
  } catch (error) {
    // TODO: handle error gracefully
    return {};
  }
};
const DELETE = async (endpoint, accessToken = null) => {
  let config = {};

  if (accessToken !== null) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }

  try {
    const response = await axios.delete(endpoint, config);

    return response.data;
  } catch (error) {
    // TODO: handle error gracefully
    return {};
  }
};

const api = { DELETE, GET, POST, PATCH };

export default api;
