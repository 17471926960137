import { useEffect, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { dissmissCookies, getCookiesStatus } from 'helpers/local-storage';

import ROUTE from 'config/routes';

import Close from 'public/static/assets/icons/close.svg';
import Cookie from 'public/static/assets/icons/cookie.svg';

const CookieBanner = ({ onAccept }) => {
  const { t } = useTranslation('common');

  const [dismissed, setDismissed] = useState(true);

  useEffect(() => {
    setDismissed(getCookiesStatus());
  }, []);

  const closeBanner = () => {
    setDismissed(true);
    onAccept();
    dissmissCookies();
  };

  if (dismissed) return null;

  return (
    <div className="cookie-banner">
      <div className="cookie-banner__box">
        <button
          type="button"
          className="cookie-banner__close"
          onClick={closeBanner}
        >
          <Close />
        </button>

        <div className="cookie-banner__info">
          <Cookie />
          <p>{t('COOKIE_BANNER')}</p>

          <div className="cookie-banner__cta">
            <Link href={ROUTE.cookiePolicy}>
              <a className="button button--border button--dark">
                {t('CTA.READ_MORE')}
              </a>
            </Link>

            <button className="button button--border" onClick={closeBanner}>
              {t('CTA.ACCEPT')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
