import { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useSession, signOut } from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';

import SummerSchoolBanner from 'components/summer-school-banner';

// TODO: remove this
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useUi } from 'app/uiStore';

import ROUTE from 'config/routes';
import { LEARNING_PATHS } from 'config/constants';

const SiteHeader = () => {
  const { t } = useTranslation('common');

  const { isBannerVisible, closeBanner } = useUi();

  const { data: session, status } = useSession();
  const router = useRouter();

  const [defaultLayout, setDefaultLayout] = useState(true);
  const [transformOut, setTransformOut] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const loading = status === 'loading';

  const onInternal =
    status === 'authenticated' || router.pathname === ROUTE.signin;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (prevPos.y >= 0) return;

      const isBase = currPos.y > -300;
      if (isBase !== defaultLayout) setDefaultLayout(isBase);

      const isHidden = currPos.y < prevPos.y && currPos.y < -300;
      if (isHidden !== transformOut) setTransformOut(isHidden);
    },
    [transformOut],
  );

  useEffect(() => {
    if (onInternal) {
      closeBanner();
    }
  }, [onInternal]);

  useEffect(() => {
    if (!mobileMenu) return;

    setMobileMenu(false);
    document.body.classList.remove('no-scroll');
  }, [router.pathname]);

  const toggleMenu = () => {
    const visible = !mobileMenu;
    setMobileMenu(visible);

    if (visible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const signout = () => {
    signOut({ callbackUrl: ROUTE.home });
  };

  return (
    <header
      className={`site-header${transformOut ? ' site-header--out' : ''}${
        isBannerVisible ? ' site-header--w-banner' : ''
      }${defaultLayout ? ' site-header--default' : ''}`}
    >
      {isBannerVisible && <SummerSchoolBanner />}

      <nav className={`navigation${mobileMenu ? ' navigation--mobile' : ''}`}>
        <div className="navigation__content">
          <div className="u-container">
            <Link href="/">
              <a>
                <img
                  className="navigation__logo"
                  src="/static/assets/logo.svg"
                  alt="TDL School logo"
                />
              </a>
            </Link>

            {!(router.pathname === ROUTE.home && loading) && (
              <div
                className={`navigation__menu-button${
                  mobileMenu ? ' navigation__menu-button--open' : ''
                }`}
                onClick={toggleMenu}
              >
                <div className="icon" />
              </div>
            )}

            {router.pathname !== ROUTE.signin &&
              !(router.pathname === ROUTE.home && loading) && (
                <div
                  className={`navigation__menu${
                    mobileMenu ? ' navigation__menu--visible' : ''
                  } ${
                    isBannerVisible ? 'navigation__menu--banner-visible' : ''
                  }`}
                >
                  <ul>
                    <li>
                      <Link href={ROUTE.calendar}>
                        <a
                          className={`link${
                            router.pathname === ROUTE.calendar
                              ? ' link--active'
                              : ''
                          }`}
                        >
                          {t('PAGES.CALENDAR')}
                        </a>
                      </Link>
                    </li>

                    {!session && (
                      <li>
                        <Link href={ROUTE.selfStudy}>
                          <a
                            className={`link${
                              router.pathname === ROUTE.selfStudy
                                ? ' link--active'
                                : ''
                            }`}
                          >
                            {t('PAGES.SELF_STUDY')}
                          </a>
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link href={ROUTE.courses}>
                        <a
                          className={`link${
                            router.pathname === ROUTE.courses
                              ? ' link--active'
                              : ''
                          }`}
                        >
                          {t('PAGES.COURSES')}
                        </a>
                      </Link>
                    </li>

                    {LEARNING_PATHS && (
                      <li>
                        <Link href={ROUTE.learningPaths}>
                          <a
                            className={`link${
                              router.pathname === ROUTE.learningPaths
                                ? ' link--active'
                                : ''
                            }`}
                          >
                            {t('PAGES.LEARNING_PATHS')}
                          </a>
                        </Link>
                      </li>
                    )}

                    {!session && (
                      <li>
                        <Link href={ROUTE.external.typesOfCourses}>
                          <a
                            className={`link${
                              router.pathname === ROUTE.external.typesOfCourses
                                ? ' link--active'
                                : ''
                            }`}
                          >
                            {t('PAGES.TYPES_OF_CURSES')}
                          </a>
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link href={ROUTE.careerPaths}>
                        <a
                          className={`link${
                            router.pathname === ROUTE.careerPaths
                              ? ' link--active'
                              : ''
                          }`}
                        >
                          {t('PAGES.CAREER_PATHS')}
                        </a>
                      </Link>
                    </li>
                  </ul>

                  {session && (
                    <Fragment>
                      <div className="navigation__logout" onClick={signout}>
                        {t('CTA.LOGOUT')}
                      </div>

                      <div className="navigation__profile">
                        <div className="avatar">
                          <img
                            src={session.user.image}
                            alt={session.user.name}
                          />
                        </div>

                        <div className="dropdown">
                          <button onClick={signout}>{t('CTA.LOGOUT')}</button>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default SiteHeader;
