const ROUTE = {
  home: '/',
  myCourses: '/my-courses',
  calendar: '/calendar',
  selfStudy: '/self-study',
  courses: '/courses',
  learningPaths: '/learning-paths',
  privacyPolicy: '/privacy-policy',
  careerPaths: '/career-paths',
  termsOfUse: '/terms-of-use',
  cookiePolicy: '/cookie-policy',
  refundPolicy: '/refund-policy',
  external: {
    apply: '/apply',
    typesOfCourses: '/types-of-courses',
    summerSchool: '/courses/testdevlab-summer-school',
  },
  sponsored: '/courses/testdevlab-summer-school-2',
  login: '/login',
  signin: '/auth/signin',
  internal: '/internal',
};

export default ROUTE;
