// TODO: get page routes from constants
module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common'],
    '/': ['common', 'testimonials'],
    '/cookie-policy': ['common', 'cookie-policy'],
    '/privacy-policy': ['common', 'privacy-policy'],
    '/refund-policy': ['common', 'refund-policy'],
    '/terms-of-use': ['common', 'terms-of-use'],
    '/types-of-courses': ['common', 'types-of-courses'],
    '/self-study': ['common', 'self-study'],
    '/career-paths': ['common', 'career-paths'],
  },
};
